<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="text-none"
          v-on="on"
        >
          <v-icon
            left
            size="16"
          >
            {{ icons.mdiFilter }}
          </v-icon>
          <span class="font-weight-regular">Filter by {{ tableName }}</span>
          <span
            v-if="tableName !== ''"
            class="font-weight-black"
            style="margin-left:3px"
          > from </span>
          <span
            class="font-weight-black"
            style="margin-left:3px"
          > {{ filterLabel }}</span>
          <v-icon
            right
            size="16"
          >
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <date-filter
          v-if="showDateFilter"
          :default-filter="defaultDatePickerDate"
          @date-change="onDateChange"
        />
        <customer-status-filter
          v-if="showAccountBalanceStatusFilter || showConfigurationDetails"
          :initial-filters="filters.customerStatus"
          :show-service-status="showAccountBalanceStatusFilter"
          :show-configuration-details="showConfigurationDetails"
          @status-change="onCustomerAccountStatusChange"
        />
        <payment-status-filter
          v-if="showPaymentStatusFilter"
          @status-change="onPaymentStatusChange"
        />
        <month-filter
          v-if="showMonthFilter"
          @month-change="onMonthChange"
        />
        <year-filter
          v-if="showYearFilter"
          @year-change="onYearChange"
        />
        <sms-status-filter
          v-if="showSmsStatusFilter"
          @status-change="onSmsStatusChange"
        />
        <payment-method-filter
          v-if="showPaymentMethodFilter"
          @status-change="onPaymentMethodChange"
        />
        <payment-analytics-category-filter
          v-if="showPaymentAnalyticsCategoryFilter"
          @payment-type-change="onPaymentTypeChange"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="onFilterButtonClick"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiFilter,
  mdiChevronDown,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DateFilter from './DateFilter.vue'
import PaymentStatusFilter from './PaymentStatusFilter.vue'
import PaymentAnalyticsCategoryFilter from './PaymentAnalyticsCategoryFilter.vue'
import MonthFilter from './MonthFileter.vue'
import YearFilter from './YearFilter.vue'
import SmsStatusFilter from './SmsStatusFilter.vue'
import CustomerStatusFilter from './CustomerStatusFilter.vue'
import PaymentMethodFilter from './PaymentMethodFilter.vue'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'

export default {
  components: {
    DateFilter, PaymentStatusFilter, MonthFilter, YearFilter, SmsStatusFilter, CustomerStatusFilter, PaymentMethodFilter, PaymentAnalyticsCategoryFilter,
  },
  mixins: [defaultMonthFilterDate],
  props: {
    tableName: {
      type: String,
      default: '',
    },
    showDateFilter: {
      type: Boolean,
      default: true,
    },
    showMonthFilter: {
      type: Boolean,
      default: false,
    },
    showYearFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentStatusFilter: {
      type: Boolean,
      default: false,
    },
    showSmsStatusFilter: {
      type: Boolean,
      default: false,
    },
    showAccountBalanceStatusFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentMethodFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentAnalyticsCategoryFilter: {
      type: Boolean,
      default: false,
    },
    showConfigurationDetails: {
      type: Boolean,
      default: false,
    },
    defaultDatePickerDate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      icons: {
        mdiFilter,
        mdiChevronDown,
      },
      filterLabel: 'Last 30 days',
      filters: {},
    }
  },
  computed: {
    ...mapGetters(['companyName']),
  },
  watch: {
    label(newVal) {
      if (newVal !== '' && this.showMonthFilter) {
        this.filterLabel = this.defaultMonthDate.format('MMM YYYY')
      } else if (newVal !== '' && this.showYearFilter) {
        this.filterLabel = moment().format('YYYY')
      } else {
        this.filterLabel = newVal
      }
    },
    filterLabel(newLabel) {
      this.onFilterLabelChange(newLabel)
    },
    defaultDatePickerDate(defaultDatePickerDate) {
      let fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      let toDate = moment().format('YYYY-MM-DD')
      if (defaultDatePickerDate === 'Last 1 year') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    },
  },
  mounted() {
    if (this.initialFilters) {
      this.filters = this.initialFilters
    }
    if (this.showSmsStatusFilter) {
      this.filters.smsStatus = {
        delivered: false,
        sent: false,
        failed: false,
      }
    }
    if (this.showDateFilter) {
      let fromDate
      let toDate

      fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      toDate = moment().format('YYYY-MM-DD')

      if (this.defaultDatePickerDate === 'Last 7 days') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    }
    if (this.showMonthFilter) {
      this.filterLabel = this.defaultMonthDate.format('MMM YYYY')
    }
    if (this.showYearFilter) {
      this.filterLabel = moment().format('YYYY')
    }
    if (this.showAccountBalanceStatusFilter) {
      this.filterLabel = 'all customers'
    }
    if (this.showPaymentAnalyticsCategoryFilter) {
      this.filterLabel = 'all payments'
    }
    if (this.label) {
      this.filterLabel = this.label
    }
    this.onFilterLabelChange(this.filterLabel)
  },
  methods: {
    onDateChange(value) {
      this.filters.dates = value.filters
      this.filterLabel = value.label
    },
    onMonthChange(value) {
      this.filters.month = value.filters
      this.filterLabel = value.label
    },
    onYearChange(value) {
      this.filters.year = value.filters
      this.filterLabel = value.label
    },
    onPaymentStatusChange(value) {
      this.filters.paymentStatus = value
    },
    onCustomerAccountStatusChange(value) {
      this.filters.accountBalanceStatus = value.filters
      if (value.label !== 'all customers') {
        this.filterLabel = `customers with ${value.label}`

        return
      }
      this.filterLabel = value.label
    },
    onSmsStatusChange(value) {
      this.filters.smsStatus = value
    },
    onPaymentMethodChange(value) {
      this.filters.paymentMethod = value.filters
    },
    onPaymentTypeChange(value) {
      this.filters.paymentType = value.value
      this.filterLabel = value.label
    },
    onFilterLabelChange(newLabel) {
      if (this.showDateFilter && this.tableName !== '' && this.filters.dates) {
        const { fromDate, toDate } = this.filters.dates
        const formattedFromDate = this.$options.filters.formatDay(fromDate, 'YYYY-MM-DD')
        const formattedToDate = this.$options.filters.formatDay(toDate, 'YYYY-MM-DD')

        const dateRange = moment(fromDate).isSame(toDate, 'day')
          ? formattedFromDate
          : `${formattedFromDate} to ${formattedToDate}`

        const emittedValue = `${this.companyName} ${this.tableName} from ${dateRange}`
        this.$emit('filterLabelChange', this.toTitleCase(emittedValue))
      } else {
        const emittedValue = `${this.companyName} ${this.tableName}${this.tableName !== '' ? ' from ' : ''}${newLabel}`
        this.$emit('filterLabelChange', this.toTitleCase(emittedValue))
      }
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    onFilterButtonClick() {
      this.menu = false
      this.$emit('filter', this.filters)
    },
  },

}
</script>
